var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"lists d-inline-flex ml-1 rounded overflow-hidden"},_vm._l((_vm.board.lists),function(list,listIndex){return _c('b-card',{key:listIndex,staticClass:"overflow-hidden align-self-start mr-1",attrs:{"body-class":"p-0 kanban-list","bg-variant":_vm.darkTheme ? 'black' : 'transparent',"text-variant":_vm.darkTheme ? 'light' : 'dark'}},[(list.games.length)?_vm._l((list.games),function(game,index){return _c('div',{key:index,staticClass:"p-1 d-flex",class:[
          game.id === _vm.currentGameId
            ? 'bg-danger'
            : _vm.darkTheme
              ? 'border-black bg-dark'
              : 'border-light bg-white',
          {
            'border-bottom' : index !== list.games.length - 1,
          }
        ],staticStyle:{"width":"60px"}},[_c('b-avatar',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticStyle:{"border-radius":"4px !important"},attrs:{"text":" ","src":_vm.currentGameId && game.src && game.id === _vm.currentGameId ? game.src : _vm.showGameThumbnails && game.src ? game.src : null,"variant":_vm.darkTheme ? 'black' : 'light',"size":"24"},nativeOn:{"click":function($event){return _vm.highlightGame(game.id)}}})],1)}):_c('div',{staticClass:"rounded overflow-hidden",staticStyle:{"height":"28px","width":"60px"}})],2)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }