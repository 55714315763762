var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.game)?_c('div',{staticClass:"game-card position-relative rounded cursor-pointer",class:[
    {
      vertical: _vm.vertical,
      small: _vm.small,
      slim: _vm.slim,
      highlighted: _vm.highlightedGame === _vm.gameId,
      'semi-transparent': _vm.transparencyEnabled,
    },
    _vm.darkTheme ? 'dark text-light' : 'light' ],on:{"click":_vm.handleClick}},[(!_vm.hideCover || _vm.hideTitle)?_c('div',[_c('div',{staticClass:"position-relative"},[(_vm.user)?_c('b-button',{staticClass:"like-button m-1",class:{ 'liked': _vm.isLiked },attrs:{"size":"sm","variant":"transparent"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.$bus.$emit('LIKE_UNLIKE_GAME', _vm.game.id)}}},[_c('i',{staticClass:"fa-fw",class:[_vm.isLiked ? 'fa-solid': 'fa-regular' , 'fa-heart text-danger']})]):_vm._e(),_c('b-img',{class:_vm.vertical && _vm.fluid ? '' : 'mw-100',attrs:{"blank-color":"#ccc","rounded":"","width":_vm.small ? '96' : null,"src":_vm.$options.getImageUrl(_vm.game, _vm.$options.IMAGE_SIZE_COVER_SMALL),"alt":_vm.game.name}}),(!_vm.hideRibbon)?_c('GameRibbon',{attrs:{"game":_vm.game}}):_vm._e()],1)]):_vm._e(),_c('aside',[(_vm.showGameProgress)?_c('b-progress',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"game-progress",attrs:{"title":(_vm.gameProgress + "% Completed"),"value":_vm.gameProgress,"variant":_vm.gameProgress == 100 ? 'success' : 'primary',"height":"8px"}}):_vm._e(),(!_vm.hideProgress && _vm.gameProgress > 0)?_c('b-badge',{staticClass:"mr-1",attrs:{"rounded":"","variant":"success","size":"sm"}},[(_vm.gameProgress == 100)?_c('i',{staticClass:"fas fa-check fa-fw",attrs:{"aria-hidden":""}}):_c('small',[_vm._v(_vm._s(_vm.gameProgress)+"%")])]):_vm._e(),(!_vm.hideTitle || _vm.hideCover)?_c('h2',{class:['text-wrap',
        {
          'text-success' : _vm.gameCompleted, 'mb-1': !_vm.board.grid,
          'mt-2': _vm.vertical,
        }
      ]},[(_vm.ranked)?_c('b-avatar',{staticClass:"d-inline-block",attrs:{"variant":_vm.darkTheme ? 'warning' : 'success',"rounded":"","size":"sm"}},[_vm._v(" "+_vm._s(_vm.rank)+" ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.game.name)+" ")],1):_vm._e(),(!_vm.hideTags && _vm.tagsApplied.length)?_vm._l((_vm.tagsApplied),function(ref){
      var bgColor = ref.bgColor;
      var textColor = ref.textColor;
      var name = ref.name;
return _c('b-button',{key:name,staticClass:"mr-2 mb-1 p-0 px-2",style:(("background-color: " + bgColor + "; color: " + textColor)),attrs:{"rounded":"","size":"sm","variant":"transparent"}},[_c('small',[_vm._v(_vm._s(name))])])}):_vm._e(),(!_vm.hideNotes && _vm.gameNotes)?[_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"fas fa-book note-indicator text-warning",attrs:{"title":"See game notes"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.$router.push({ name: 'game.notes', params: { id: _vm.game.id, slug: _vm.game.slug }})}}})]:_vm._e(),(!_vm.hidePlatforms && _vm.gamePlatformsText)?_c('div',{staticClass:"d-flex mb-2"},[_c('small',[_vm._v(_vm._s(_vm.gamePlatformsText))])]):_vm._e()],2)]):_c('div',[_c('b-img',{staticClass:"mw-100",attrs:{"alt":String(_vm.gameId),"blank-color":"#ccc","rounded":"","src":"/placeholder.gif"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }