export default {
  board: {},
  highlightedGame: null,
  boards: [],
  games: {},
  dragging: false,
  game: {},
  cachedGames: {},
  notes: {},
  notification: false,
  platforms: [],
  profile: {},
  profiles: [],
  progresses: {},
  publicBoards: [],
  releases: [],
  sessionExpired: false,
  settings: {},
  tags: [],
  twitchToken: null,
  user: null,
  wallpapers: [],
  activeWallpaper: {},
  activeTagIndex: null,
  activeBoardListIndex: null,
  gameSelectorData: null,
  menuOpen: true,
  editProfileSidebarOpen: false,
  routeName: null,
};
